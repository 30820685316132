






















































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import moment from "moment";
import { ResponsePagination } from "@/models/interface/common.interface";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ResponseListOfCalendar } from "@/models/interface/settings.interface";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  name: "Calendar",
  components: { DatePicker },
  data() {
    return {
      startYear: "" as string,
      endYear: "" as string,
      valueDate: [] as string[],
      dataListModul: {} as any,
      form: this.$form.createForm(this, { name: "calendar" }),
      page: this.page || (1 as number),
      limit: this.limit || (16 as number),
      search: "" as string,
      dataDetails: [] as any,
      sort: "startFrom:asc" as string,
      loadingTable: false as boolean,
      titlemodalOpen: "CALENDAR" as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      isFormSubmittedGenerate: false as boolean,
      direction: "asc" as string,
      formRules: {
        start: {
          label: this.$t("lbl_start_year"),
          name: "start",
          placeholder: this.$t("lbl_start_year"),
          decorator: [
            "start",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        end: {
          label: this.$t("lbl_end_year"),
          name: "end",
          placeholder: this.$t("lbl_end_year"),
          decorator: [
            "end",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        moduls: {
          label: this.$t("lbl_module"),
          name: "moduls",
          placeholder: this.$t("lbl_module"),
          decorator: [
            "moduls",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_month"),
          dataIndex: "month",
          key: "month",
          sorter: true,
          width: 100,
        },
        {
          title: this.$t("lbl_year"),
          dataIndex: "year",
          key: "year",
          sorter: true,
          width: 100,
        },
        {
          title: this.$t("lbl_quarter"),
          dataIndex: "quarter",
          key: "quarter",
          sorter: true,
          width: 100,
        },
        {
          title: this.$t("lbl_from"),
          dataIndex: "startFrom",
          key: "from",
          sorter: true,
          width: 100,
        },
        {
          title: this.$t("lbl_to"),
          dataIndex: "endTo",
          key: "to",
          sorter: true,
          width: 100,
        },
        {
          title: this.$t("lbl_close_period"),
          dataIndex: "calendarClosePeriodsDisplay",
          key: "close",
          width: 200,
          scopedSlots: { customRender: "isRenderTag" },
        },
      ] as any,
      dataListRates: {} as ResponseListOfCalendar,
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "calendar" && x.privilege.update && x.privilege.create);
    },
  },
  mounted() {
    this.getListOfMasterType();
    this.getListOfCalendar("");
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "calendar") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    submitFormEdit(): void {
      const dataPush = [] as any;
      this.dataDetails.forEach((element) => {
        const dataObj = {
          calendarClosePeriodId: element.id,
          closePeriod: element.closePeriod,
        };
        dataPush.push(dataObj);
      });
      const dataPost = {
        requestBody: dataPush,
      };
      this.isFormSubmitted = true;
      settingsServices
        .updateCalendarClosePeriod(dataPost)
        .then((res: any) => {
          this.modalOpen = false;
          this.isFormSubmitted = false;
          this.getListOfCalendar("");
          this.$message.success(Messages.UPDATE_SUCCESS);
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    handleSelectChange(name, data): void {
      this.dataDetails.find((item) => item.modulName === name).closePeriod =
        data === "true" ? true : false;
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
    reponseEditTable(response): void {
      this.dataDetails = response.data.calendarClosePeriods;
      this.modalOpen = true;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfCalendar("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfCalendar("");
    },
    moment,
    onSorterChange(response): void {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      if (response.sort.field) this.sort = `startFrom:${this.direction}`;
      else this.sort = `${response.sort.field}:${this.direction}`;
      this.getListOfCalendar("");
    },
    handlePanelChange2(value) {
      this.valueDate = value;
    },
    handleChangeDate(value) {
      this.valueDate = value;
    },
    submitForm(): void {
      this.form.validateFields((err, values) => {
        if (!err && this.startYear && this.endYear) {
          const arrObj = [] as any;
          values.moduls.forEach((element) => {
            const obj = {
              masterTypeId: element,
              closePeriod: false,
            };
            arrObj.push(obj);
          });
          const dataPost = {
            start:
              this.moment(this.startYear).format("YYYY") + "-01-01T01:00:00+00",
            end:
              this.moment(this.endYear).format("YYYY") + "-12-31T23:59:00+00",
            moduls: arrObj,
          };
          this.isFormSubmittedGenerate = true;
          this.loadingTable = true;
          settingsServices
            .createCalendar(dataPost)
            .then(() => {
              this.isFormSubmittedGenerate = false;
              this.form.resetFields();
              this.startYear = "";
              this.endYear = "";
              this.getListOfCalendar("");
              this.$message.success(this.$t("notif_create_success").toString());
            })
            .catch((err) => (this.isFormSubmittedGenerate = false))
            .finally(() => (this.loadingTable = false));
        } else {
          this.$notification["error"]({
            message: "Error",
            description: "Form is mandatory",
          });
        }
      });
    },
    getListOfMasterType(): void {
      let params = {
        name: "CALENDAR_MODULE",
      } as RequestQueryParamsModel;
      masterServices.listMaster(params).then((res: any) => {
        res.map((dataObject, index) => (dataObject.key = index));
        this.dataListModul = res;
      });
    },
    getListOfCalendar(path: string): void {
      let params: any;
      if (!path) {
        params = {
          limit: this.limit,
          page: this.page - 1,
          sorts: "startFrom:asc",
        } as RequestQueryParamsModel;
        if (this.search) params.search = this.search;
        if (this.direction) params.sorts = this.sort;
      }
      this.loadingTable = true;
      settingsServices
        .listOfCalendar(params, path)
        .then((res: any) => {
          if (!path) {
            res.data.forEach((element) => {
              const dataClose = [] as any;
              element.calendarClosePeriods.forEach((item, index) => {
                const typeclosePeriod =
                  item.closePeriod === true ? "Closed" : "Open";
                const nameClose =
                  item.modulName + "=" + typeclosePeriod + "<br>";
                dataClose.push(nameClose);
                element["calendarClosePeriodsDisplay"] = dataClose
                  .join("")
                  .toString();
              });
            });
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListRates = res;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loadingTable = false));
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
